<template>
  <b-row>
    <b-col cols="12">
      <b-card title="All Events">

        <!-- search input -->
        <div class="custom-search d-flex justify-content-start">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search"
                type="text"
              />
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :to="{name: 'add-events'}"
                class="d-inline-flex mr-1"
                variant="outline-success"
              >
                <feather-icon
                  class="mr-50"
                  icon="PlusIcon"
                />
                <span class="align-middle text-nowrap">Add Event</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="d-inline-flex mr-1"
                variant="outline-primary"
              >
                <feather-icon
                  class="mr-50"
                  icon="PlusIcon"
                />
                <span class="align-middle text-nowrap">Assign Team</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="d-inline-flex mr-1"
                variant="outline-dark"
              >
                <feather-icon
                  class="mr-50"
                  icon="UserIcon"
                />
                <span class="align-middle text-nowrap">Invite Applications</span>
              </b-button>
            </div>
          </b-form-group>
        </div>
        <b-overlay
          :show="$apollo.loading"
          spinner-variant="primary"
        >
          <!-- table -->
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    dropright
                    no-caret
                    size="sm"
                    toggle-class="text-decoration-none"
                    variant="link"
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        class="text-body align-middle"
                        icon="MoreVerticalIcon"
                        size="16"
                      />
                    </template>
                    <b-dropdown-item>
                      <feather-icon
                        class="mr-50"
                        icon="Edit2Icon"
                      />
                      <span>View or Update Details</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="props.row.resourceType == 'Shared Resource' ? $bvModal.show('sharedResource'): $bvModal.show('unitResource')">
                      <feather-icon
                        class="mr-50"
                        icon="MailIcon"
                      />
                      <span>Email Participant(s)</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <feather-icon
                        class="mr-50"
                        icon="FilmIcon"
                      />
                      <span>Manage Media</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <feather-icon
                        class="mr-50"
                        icon="HelpCircleIcon"
                      />
                      <span>Queries</span>
                    </b-dropdown-item>
                    <b-dropdown-item variant="danger">
                      <feather-icon
                        class="mr-50"
                        icon="TrashIcon"
                      />
                      <span>Delete Event</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
              <!-- Column: Name -->
              <span
                v-else-if="props.column.field === 'fullName'"
                class="text-nowrap"
              >
                <span class="text-nowrap">{{ props.row.fullName }}</span>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status }}
                </b-badge>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-overlay>
      </b-card>
    </b-col>
    <!-- Ind/Limited Res. modal -->
    <b-modal
      id="unitResource"
      hide-header-close
      ok-only
      title="Basic Modal"
    >
      <b-card-text>
        <i>Total Units Available:</i> 12<br>
        <i>Totat Units Availed by Startups:</i> 3 <br>
        <span class="font-medium-2 text-primary">Grand Total: 15 <br></span>
      </b-card-text>
    </b-modal>
    <b-modal
      id="sharedResource"
      hide-header-close
      ok-only
      title="Basic Modal"
    >
      <b-card-text>
        Shared Resource Booking.
      </b-card-text>
    </b-modal>
  </b-row>

</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BCard,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BOverlay,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Title',
          field: 'event_name',
        },
        {
          label: 'Organiser',
          field: row => (row.events_partnerstables?.length > 1 ? row.events_partnerstables.map(e => e.networks_basicinfo.name).join(', ') : '-'),

        },
        {
          label: 'Target Audience',
          field: row => (row.events_targetparticipanttables?.length > 1 ? row.events_targetparticipanttables.map(e => e.target_participant_type).join(', ') : '-'),
        },
        {
          label: 'Total Registrations',
          field: row => row.events_applicantstables_aggregate.aggregate.count,
        },
        {
          label: 'Price',
          field: 'price',
          formatFn(value) {
            return `₹${value}`
          },
        },
        {
          label: 'Date',
          field: row => (row.events_scheduletables.length > 0 ? new Date(row.events_scheduletables[0].date).toLocaleDateString() : '-'),
        },
        {
          label: 'Capacity',
          field: 'capacity',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  apollo: {
    rows: {
      query: gql`
        {
          events_basicinfo {
            event_name
            events_partnerstables(where: {designation: {_eq: "Organiser"}}) {
              networks_basicinfo {
                name
              }
            }
            events_applicantstables_aggregate {
              aggregate {
                count
              }
            }
            price
            events_scheduletables(order_by: {date: asc}, limit: 1) {
              date
            }
            events_targetparticipanttables {
              target_participant_type
            }
            capacity
          }
        }
      `,
      update: data => data.events_basicinfo,
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
